<template>
  <div>
    <div class="d-block d-md-none mb-2">
      <b-button
        variant="primary"
        block
        @click="actualizarPermisos"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span>Grabar</span>
      </b-button>
      <b-button
        variant="danger"
        block
        @click="limpiarTotal"
      >
        <feather-icon
          icon="FileIcon"
          class="mr-50"
        />
        <span>Limpiar</span>
      </b-button>
    </div>
    <div class="d-none d-md-flex align-items-center justify-content-end mb-2">
      <b-button
        class="mr-50"
        variant="primary"
        @click="actualizarPermisos"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span>Grabar</span>
      </b-button>
      <b-button
        variant="danger"
        @click="limpiarTotal"
      >
        <feather-icon
          icon="FileIcon"
          class="mr-50"
        />
        <span>Limpiar</span>
      </b-button>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <validation-observer ref="filter">
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group
                  label="Rol"
                  label-for="rol"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="rol"
                    rules="required"
                  >
                    <b-form-select
                      id="rol"
                      ref="rol"
                      v-model="selectedRol"
                      :options="optionsRol"
                      :state="errors.length > 0 ? false : null"
                      @input="obtenerPermisos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card title="Lista de módulos">
          <b-row>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    Módulo
                  </th>
                  <th scope="col">
                    Ver
                  </th>
                  <th scope="col">
                    Crear
                  </th>
                  <th scope="col">
                    Actualizar
                  </th>
                  <th scope="col">
                    Eliminar
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="col">
                    Cargas
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="cargasVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="cargasCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="cargasActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="cargasEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Cupon
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="cuponVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="cuponCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="cuponActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="cuponEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Documentacion
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="documentacionVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="documentacionCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="documentacionActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="documentacionEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Dashboard
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="dashboardVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="dashboardCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="dashboardActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="dashboardEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Empresa
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="empresaVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="empresaCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="empresaActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="empresaEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Notificación
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="notificacionVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="notificacionCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="notificacionActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="notificacionEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Tienda
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="tiendaVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="tiendaCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="tiendaActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="tiendaEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Permiso
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="permisoVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="permisoCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="permisoActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="permisoEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Reportes
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="reporteVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="reporteCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="reporteActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="reporteEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr v-if="userData.companyid== '1'">
                  <th scope="col">
                    Rol
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="rolVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="rolCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="rolActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="rolEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Trivia
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="triviaVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="triviaCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="triviaActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="triviaEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Mapa
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="mapaVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="mapaCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="mapaActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="mapaEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    Feria
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="feriaVer"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="feriaCrear"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="feriaActualizar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                  <th scope="col">
                    <b-form-checkbox
                      v-model="feriaEliminar"
                      value="1"
                      unchecked-value="0"
                    />
                  </th>
                </tr>
              </tbody>
            </table>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import permisoService from '@/service/permisoService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('token'),
      required,
      selectedRol: null,
      optionsRol: [
        { value: null, text: 'Seleccionar rol' },
      ],
      cargasVer: '0',
      cargasCrear: '0',
      cargasActualizar: '0',
      cargasEliminar: '0',
      cuponVer: '0',
      cuponCrear: '0',
      cuponActualizar: '0',
      cuponEliminar: '0',
      dashboardVer: '0',
      dashboardCrear: '0',
      dashboardActualizar: '0',
      dashboardEliminar: '0',
      documentacionVer: '0',
      documentacionCrear: '0',
      documentacionActualizar: '0',
      documentacionEliminar: '0',
      empresaVer: '0',
      empresaCrear: '0',
      empresaActualizar: '0',
      empresaEliminar: '0',
      notificacionVer: '0',
      notificacionCrear: '0',
      notificacionActualizar: '0',
      notificacionEliminar: '0',
      tiendaVer: '0',
      tiendaCrear: '0',
      tiendaActualizar: '0',
      tiendaEliminar: '0',
      permisoVer: '0',
      permisoCrear: '0',
      permisoActualizar: '0',
      permisoEliminar: '0',
      reporteVer: '0',
      reporteCrear: '0',
      reporteActualizar: '0',
      reporteEliminar: '0',
      rolVer: '0',
      rolCrear: '0',
      rolActualizar: '0',
      rolEliminar: '0',
      triviaVer: '0',
      triviaCrear: '0',
      triviaActualizar: '0',
      triviaEliminar: '0',
      mapaVer: '0',
      mapaCrear: '0',
      mapaActualizar: '0',
      mapaEliminar: '0',
      feriaVer: '0',
      feriaCrear: '0',
      feriaActualizar: '0',
      feriaEliminar: '0',
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.obtenerRol()
      this.$store.commit('app/UPDATE_ISLOADING', false)
    })
  },
  methods: {
    async obtenerRol() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseRol = await permisoService.postRolData(this.token, this.userData.companyid)
      if (responseRol.data.data.length > 0) {
        responseRol.data.data.forEach(element => this.optionsRol.push({
          value: element.id,
          text: element.nombre,
        }))
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    limpiarTotal() {
      this.$refs.filter.reset()
      this.selectedRol = null
      this.limpiarPermisos()
    },
    limpiarPermisos() {
      this.cargasVer = '0'
      this.cargasCrear = '0'
      this.cargasActualizar = '0'
      this.cargasEliminar = '0'
      this.cuponVer = '0'
      this.cuponCrear = '0'
      this.cuponActualizar = '0'
      this.cuponEliminar = '0'
      this.dashboardVer = '0'
      this.dashboardCrear = '0'
      this.dashboardActualizar = '0'
      this.dashboardEliminar = '0'
      this.documentacionVer = '0'
      this.documentacionCrear = '0'
      this.documentacionActualizar = '0'
      this.documentacionEliminar = '0'
      this.empresaVer = '0'
      this.empresaCrear = '0'
      this.empresaActualizar = '0'
      this.empresaEliminar = '0'
      this.notificacionVer = '0'
      this.notificacionCrear = '0'
      this.notificacionActualizar = '0'
      this.notificacionEliminar = '0'
      this.tiendaVer = '0'
      this.tiendaCrear = '0'
      this.tiendaActualizar = '0'
      this.tiendaEliminar = '0'
      this.permisoVer = '0'
      this.permisoCrear = '0'
      this.permisoActualizar = '0'
      this.permisoEliminar = '0'
      this.reporteVer = '0'
      this.reporteCrear = '0'
      this.reporteActualizar = '0'
      this.reporteEliminar = '0'
      this.rolVer = '0'
      this.rolCrear = '0'
      this.rolActualizar = '0'
      this.rolEliminar = '0'
      this.triviaVer = '0'
      this.triviaCrear = '0'
      this.triviaActualizar = '0'
      this.triviaEliminar = '0'
      this.mapaVer = '0'
      this.mapaCrear = '0'
      this.mapaActualizar = '0'
      this.mapaEliminar = '0'
      this.feriaVer = '0'
      this.feriaCrear = '0'
      this.feriaActualizar = '0'
      this.feriaEliminar = '0'
    },
    async obtenerPermisos() {
      this.limpiarPermisos()
      if (this.selectedRol != null) {
        this.$store.commit('app/UPDATE_ISLOADING', true)
        const responsePermisos = await permisoService.postPermisosData(this.token, this.selectedRol)
        if (responsePermisos.data.data[0].permisos !== '') {
          const json = JSON.parse(responsePermisos.data.data[0].permisos)
          if (json.cargasVer !== undefined) {
            this.cargasVer = json.cargasVer
          }
          if (json.cargasCrear !== undefined) {
            this.cargasCrear = json.cargasCrear
          }
          if (json.cargasActualizar !== undefined) {
            this.cargasActualizar = json.cargasActualizar
          }
          if (json.cargasEliminar !== undefined) {
            this.cargasEliminar = json.cargasEliminar
          }

          if (json.cuponVer !== undefined) {
            this.cuponVer = json.cuponVer
          }
          if (json.cuponCrear !== undefined) {
            this.cuponCrear = json.cuponCrear
          }
          if (json.cuponActualizar !== undefined) {
            this.cuponActualizar = json.cuponActualizar
          }
          if (json.cuponEliminar !== undefined) {
            this.cuponEliminar = json.cuponEliminar
          }

          if (json.dashboardVer !== undefined) {
            this.dashboardVer = json.dashboardVer
          }
          if (json.dashboardCrear !== undefined) {
            this.dashboardCrear = json.dashboardCrear
          }
          if (json.dashboardActualizar !== undefined) {
            this.dashboardActualizar = json.dashboardActualizar
          }
          if (json.dashboardEliminar !== undefined) {
            this.dashboardEliminar = json.dashboardEliminar
          }

          if (json.documentacionVer !== undefined) {
            this.documentacionVer = json.documentacionVer
          }
          if (json.documentacionCrear !== undefined) {
            this.documentacionCrear = json.documentacionCrear
          }
          if (json.documentacionActualizar !== undefined) {
            this.documentacionActualizar = json.documentacionActualizar
          }
          if (json.documentacionEliminar !== undefined) {
            this.documentacionEliminar = json.documentacionEliminar
          }

          if (json.empresaVer !== undefined) {
            this.empresaVer = json.empresaVer
          }
          if (json.empresaCrear !== undefined) {
            this.empresaCrear = json.empresaCrear
          }
          if (json.empresaActualizar !== undefined) {
            this.empresaActualizar = json.empresaActualizar
          }
          if (json.empresaEliminar !== undefined) {
            this.empresaEliminar = json.empresaEliminar
          }

          if (json.notificacionVer !== undefined) {
            this.notificacionVer = json.notificacionVer
          }
          if (json.notificacionCrear !== undefined) {
            this.notificacionCrear = json.notificacionCrear
          }
          if (json.notificacionActualizar !== undefined) {
            this.notificacionActualizar = json.notificacionActualizar
          }
          if (json.notificacionEliminar !== undefined) {
            this.notificacionEliminar = json.notificacionEliminar
          }

          if (json.tiendaVer !== undefined) {
            this.tiendaVer = json.tiendaVer
          }
          if (json.tiendaCrear !== undefined) {
            this.tiendaCrear = json.tiendaCrear
          }
          if (json.tiendaActualizar !== undefined) {
            this.tiendaActualizar = json.tiendaActualizar
          }
          if (json.tiendaEliminar !== undefined) {
            this.tiendaEliminar = json.tiendaEliminar
          }

          if (json.permisoVer !== undefined) {
            this.permisoVer = json.permisoVer
          }
          if (json.permisoCrear !== undefined) {
            this.permisoCrear = json.permisoCrear
          }
          if (json.permisoActualizar !== undefined) {
            this.permisoActualizar = json.permisoActualizar
          }
          if (json.permisoEliminar !== undefined) {
            this.permisoEliminar = json.permisoEliminar
          }

          if (json.reporteVer !== undefined) {
            this.reporteVer = json.reporteVer
          }
          if (json.reporteCrear !== undefined) {
            this.reporteCrear = json.reporteCrear
          }
          if (json.reporteActualizar !== undefined) {
            this.reporteActualizar = json.reporteActualizar
          }
          if (json.reporteEliminar !== undefined) {
            this.reporteEliminar = json.reporteEliminar
          }

          if (json.rolVer !== undefined) {
            this.rolVer = json.rolVer
          }
          if (json.rolCrear !== undefined) {
            this.rolCrear = json.rolCrear
          }
          if (json.rolActualizar !== undefined) {
            this.rolActualizar = json.rolActualizar
          }
          if (json.rolEliminar !== undefined) {
            this.rolEliminar = json.rolEliminar
          }
          if (json.triviaVer !== undefined) {
            this.triviaVer = json.triviaVer
          }
          if (json.triviaCrear !== undefined) {
            this.triviaCrear = json.triviaCrear
          }
          if (json.triviaActualizar !== undefined) {
            this.triviaActualizar = json.triviaActualizar
          }
          if (json.triviaEliminar !== undefined) {
            this.triviaEliminar = json.triviaEliminar
          }
          if (json.mapaVer !== undefined) {
            this.mapaVer = json.mapaVer
          }
          if (json.mapaCrear !== undefined) {
            this.mapaCrear = json.mapaCrear
          }
          if (json.mapaActualizar !== undefined) {
            this.mapaActualizar = json.mapaActualizar
          }
          if (json.mapaEliminar !== undefined) {
            this.mapaEliminar = json.mapaEliminar
          }
          if (json.feriaVer !== undefined) {
            this.feriaVer = json.feriaVer
          }
          if (json.feriaCrear !== undefined) {
            this.feriaCrear = json.feriaCrear
          }
          if (json.feriaActualizar !== undefined) {
            this.feriaActualizar = json.feriaActualizar
          }
          if (json.feriaEliminar !== undefined) {
            this.feriaEliminar = json.feriaEliminar
          }
        }
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async actualizarPermisos() {
      this.$refs.filter.reset()
      const success = await this.$refs.filter.validate()
      if (success) {
        this.$store.commit('app/UPDATE_ISLOADING', true)
        const data = {
          cargasVer: this.cargasVer,
          cargasCrear: this.cargasCrear,
          cargasActualizar: this.cargasActualizar,
          cargasEliminar: this.cargasEliminar,
          cuponVer: this.cuponVer,
          cuponCrear: this.cuponCrear,
          cuponActualizar: this.cuponActualizar,
          cuponEliminar: this.cuponEliminar,
          dashboardVer: this.dashboardVer,
          dashboardCrear: this.dashboardCrear,
          dashboardActualizar: this.dashboardActualizar,
          dashboardEliminar: this.dashboardEliminar,
          documentacionVer: this.documentacionVer,
          documentacionCrear: this.documentacionCrear,
          documentacionActualizar: this.documentacionActualizar,
          documentacionEliminar: this.documentacionEliminar,
          empresaVer: this.empresaVer,
          empresaCrear: this.empresaCrear,
          empresaActualizar: this.empresaActualizar,
          empresaEliminar: this.empresaEliminar,
          notificacionVer: this.notificacionVer,
          notificacionCrear: this.notificacionCrear,
          notificacionActualizar: this.notificacionActualizar,
          notificacionEliminar: this.notificacionEliminar,
          tiendaVer: this.tiendaVer,
          tiendaCrear: this.tiendaCrear,
          tiendaActualizar: this.tiendaActualizar,
          tiendaEliminar: this.tiendaEliminar,
          permisoVer: this.permisoVer,
          permisoCrear: this.permisoCrear,
          permisoActualizar: this.permisoActualizar,
          permisoEliminar: this.permisoEliminar,
          reporteVer: this.reporteVer,
          reporteCrear: this.reporteCrear,
          reporteActualizar: this.reporteActualizar,
          reporteEliminar: this.reporteEliminar,
          rolVer: this.rolVer,
          rolCrear: this.rolCrear,
          rolActualizar: this.rolActualizar,
          rolEliminar: this.rolEliminar,
          triviaVer: this.triviaVer,
          triviaCrear: this.triviaCrear,
          triviaActualizar: this.triviaActualizar,
          triviaEliminar: this.triviaEliminar,
          mapaVer: this.mapaVer,
          mapaCrear: this.mapaCrear,
          mapaActualizar: this.mapaActualizar,
          mapaEliminar: this.mapaEliminar,
          feriaVer: this.feriaVer,
          feriaCrear: this.feriaCrear,
          feriaActualizar: this.feriaActualizar,
          feriaEliminar: this.feriaEliminar,
        }

        await permisoService.postActualizarData(this.token, this.selectedRol, JSON.stringify(data))
        this.selectedRol = null
        this.limpiarPermisos()

        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Registro satisfactorio',
            },
          }, {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
  },
}
</script>

  <style>

  </style>
