import axios from '@axios'
import store from '@/store'

export default {
  async postRolData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/rol/getEmpresaRoles`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idEmpresa: id,
      },
    })
    return response
  },
  async postPermisosData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/rol/getPermisos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idRol: id,
      },
    })
    return response
  },
  async postActualizarData(token, id, permisos) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/rol/setPermisos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idRol: id,
        permisos,
      },
    })
    return response
  },
}
